import React, { useContext } from 'react';
import { Context } from 'store/createContext';
import { Container } from './nav.css';
import Button from '../../button';

const Nav = () => {
  const store = useContext(Context);

  const goToUrl = () => {
    //
    window.open('https://coachbuilder.com/coach-builder-summit-q-and-a/');
  };

  return (
    <Container>
      <ul>
        {store.showVideo && store.verifiedVip ? (
          <Button red={true} onClick={goToUrl}>
            Join VIP Room
          </Button>
        ) : null}
      </ul>
    </Container>
  );
};

export default Nav;
