import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import posed from 'react-pose';
import styled from 'styled-components';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import MEDIA from 'helpers/mediaTemplates';
import { Context } from 'store/createContext';

import {
  primaryText,
  offWhite,
  redGradStart,
  redGradEnd,
} from 'constants/theme';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const NavContainer = styled(Container)`
  ${MEDIA.PHONE`
     flex-direction: column;
  `};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  .gatsby-image-wrapper {
    width: 160px;
    min-width: 160px;
    margin-right: 2rem;

    ${MEDIA.PHONE`
     margin-bottom: 2rem;
  `};

    picture {
      img {
        object-fit: contain;
      }
    }
  }
`;

const StyledBanner = styled.div`
  width: 100%;
  background: ${primaryText};
  display: flex;
  align-items: center;
  justify-content: center;

  p,
  a {
    color: white;
    padding: 1.5rem;
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
`;

const TitleWrapper = styled.div`
  h1 {
    background-color: ${redGradStart};
    background-image: linear-gradient(45deg, ${redGradStart}, ${redGradEnd});
    background-clip: text;
    text-fill-color: transparent;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }

  h3 {
    color: ${redGradStart};
    color: ${offWhite};
  }
`;

//use to be the word 'with' and this made more sense...
const splitTitle = title => {
  let split = title.split('Live');
  return {
    main: split[0],
    subTitle: 'Live' + split[1],
  };
};

const Header = ({
  title,
  icon,
  bannerMessage = '',
  headerBackgroundImage = '',
}) => {
  const store = useContext(Context);

  const generateHomePath = () => {
    let path = '/';

    if (store.rsvp) {
      path = `/?rsvp=${store.rsvp}`;
    }
    if (store.vip) {
      path = `/?vip=${store.vip}`;
    }
    return path;
  };
  return (
    <AnimatedContainer>
      {bannerMessage ? (
        <StyledBanner>
          <p dangerouslySetInnerHTML={{ __html: bannerMessage }} />
        </StyledBanner>
      ) : null}
      {/* <BackgroundImage
      Tag="div"
      fluid={headerBackgroundImage.source.childImageSharp.fluid}
      style={{
        backgroundPosition: 'top center',
        width: '100%',
        // height: '30rem',
      }}
    > */}
      <NavContainer>
        <Link
          // target="_blank"
          to={generateHomePath()}
          // to="https://businessmadesimple.com/small-business-flight-school/?utm_medium=social&utm_source=fsbootcamplivestream&utm_campaign=flightschool&utm_content=header"
        >
          <FlexWrapper>
            <Img fluid={icon ? icon.childImageSharp.fluid : {}} alt={'logo'} />
            <TitleWrapper>
              {/* <Title as="h3" className="hide-big-phone">
              Donald Miller Teaches
            </Title> */}
              <Title as="h1" size="large" className="hide-big-phone">
                {/* Title maybe? Or just the logo? */}
                {/* Live Q&A */}
              </Title>
            </TitleWrapper>
          </FlexWrapper>
        </Link>
        <Nav />
      </NavContainer>
      {/* </BackgroundImage> */}
    </AnimatedContainer>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  bannerMessage: PropTypes.string,
  headerBackgroundImage: PropTypes.string,
};

export default Header;
